import React, { useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Avatar, Badge, Layout, Dropdown, Drawer, Divider } from 'antd'
import {
    // UnlockOutlined,
    // UserOutlined,
    PoweroffOutlined,
} from '@ant-design/icons'
import useAlertLogList2 from '../../hook/useAlertLogList2'
import useGridInfo from '../../hook/useGridInfo'
import { SettingContext } from '../../store/SettingProvider'
import { AuthContext } from '../../store/AuthProvider'
import { DashboardContext } from '../../store/DashboardProvider.js'
import { DashboardContext as TimeLineDashboard } from '../../store/TimeLineDashboardProvider'
import logo_s from '../../images/logo_s.svg'
import { menu } from '../../functionTool/functionTool'

const { Header } = Layout

const AppHeader = () => {
    const { dark } = useContext(SettingContext)
    const { UnAuthUser, user } = useContext(AuthContext)
    const { customTimeLineMode } = useContext(TimeLineDashboard)
    const { listID, setListID } = useContext(DashboardContext)
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const { gridInfo } = useGridInfo(listID)
    const { alertLogList2 } = useAlertLogList2({ expired: 'false' })

    //登出
    const logoutHandler = () => {
        setListID('')
        UnAuthUser()
    }

    //是否有綠電轉供
    const isGreen = gridInfo?.vpp_getTopologyVppGridInfo?.vppNodeRef?.find(
        (item) => item.position === 'front' && item.subType === 'green'
    )

    //是否有自發自用太陽能
    const isPvBehind = gridInfo?.vpp_getTopologyVppGridInfo?.vppNodeRef?.find(
        (item) => item.position === 'front' && item.label === 'pv'
    )

    //是否有自發自用風能
    const isWindBehind = gridInfo?.vpp_getTopologyVppGridInfo?.vppNodeRef?.find(
        (item) => item.position === 'front' && item.label === 'wind'
    )

    //是否顯示綠電資訊報表
    const isGreenReportShow = isGreen || isPvBehind || isWindBehind

    //錯誤訊息數量
    const alerNumber = alertLogList2?.vpp_getAlertLogList2?.filter?.(
        (item) => item.expired !== 'true'
    )?.length

    const appHeaderMenu = menu({
        alert: alerNumber,
        customTimeLineMode,
        isGreen: isGreenReportShow,
    })

    const items = [
        // {
        //     key: '1',
        //     label: <Link to="/changepassword">重設密碼</Link>,
        //     icon: (
        //         <Link to="/changepassword">
        //             <UnlockOutlined />
        //         </Link>
        //     ),
        //     name: '重設密碼',
        //     path: '/changepassword',
        // },
        // {
        //     key: '2',
        //     label: <Link to="/">帳號設定</Link>,
        //     icon: (
        //         <Link to="/">
        //             <UserOutlined />
        //         </Link>
        //     ),
        //     name: '帳號設定',
        //     path: '/',
        // },
        // {
        //     type: 'divider',
        // },
        {
            key: '3',
            label: <span onClick={logoutHandler}>登出</span>,
            icon: (
                <span onClick={logoutHandler}>
                    <PoweroffOutlined />
                </span>
            ),
            name: '登出',
            path: '',
        },
    ]
    const showDrawer = () => {
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }

    return (
        <Header className="app-header">
            <div className="app-header-menu">
                <div className="app-header-logo">
                    <Link to="/">
                        <img src={logo_s} alt="微電能源-能源管理系統" />
                    </Link>
                </div>
                <div className="app-header-note">
                    歡迎來到微電能源管理平台 {user.userName}
                </div>
                <ul className="app-header-right-menu">
                    <li>
                        <div className="user">
                            <Dropdown
                                menu={{ items }}
                                trigger={['click']}
                                overlayClassName={dark ? 'dark' : ''}
                            >
                                <div>
                                    <Badge size="small" count={0}>
                                        <Avatar
                                            size="small"
                                            style={{
                                                color: '#f56a00',
                                                backgroundColor: '#fde3cf',
                                            }}
                                        >
                                            {user?.userName
                                                ?.substr(0, 1)
                                                .toUpperCase()}
                                        </Avatar>
                                    </Badge>
                                    {/* <span className="name">
                                        {user.userName}
                                    </span> */}
                                </div>
                            </Dropdown>
                        </div>
                    </li>
                </ul>
                <div
                    className="app-header-mobile-menu"
                    onClick={() => {
                        showDrawer()
                    }}
                >
                    <em />
                    <em />
                </div>
            </div>
            <Drawer
                title=""
                placement="right"
                onClose={onClose}
                open={open}
                width={300}
                className={dark ? 'dark' : ''}
            >
                {appHeaderMenu.map((item) => (
                    <p key={item.key}>
                        <span
                            onClick={() => {
                                navigate(item.path)
                                onClose()
                            }}
                            style={{ cursor: 'pointer', display: 'block' }}
                        >
                            {item.name}
                        </span>
                        {item.name == '警告列表' ? (
                            <span className="number">{alerNumber || null}</span>
                        ) : null}
                    </p>
                ))}
                <Divider />
                {items.map((item) => (
                    <p key={item.key * 2}>
                        <span
                            onClick={() => {
                                if (item.name === '登出') {
                                    logoutHandler()
                                } else {
                                    navigate(item.path)
                                }
                                onClose()
                            }}
                        >
                            {item.name}
                        </span>
                    </p>
                ))}
            </Drawer>
        </Header>
    )
}

export default AppHeader
