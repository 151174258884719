import React from 'react'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { AuthContext } from '../store/AuthProvider.js'
import { getTopologyVppGridInfo } from '../querys/querys.js'

const useGridInfo = (listID) => {
    const { user } = React.useContext(AuthContext)

    const { data: gridInfo, ...arg } = useQuery({
        queryKey: ['getTopologyVppGridInfo', { listID }],
        queryFn: async ({ queryKey }) => {
            try {
                const response = await axios({
                    method: 'POST',
                    // eslint-disable-next-line no-undef
                    url: process.env.REACT_APP_API,
                    headers: {
                        Authorization: user.token,
                    },
                    data: {
                        query: getTopologyVppGridInfo,
                        variables: {
                            id: queryKey[1].listID,
                        },
                    },
                })
                return response.data.data // Response received from the API
            } catch (error) {
                return await Promise.reject(error)
            }
        },
        enabled: !!listID,
    })

    return { gridInfo, ...arg }
}

export default useGridInfo
