/* eslint-disable no-undef */
import React, { useState, useContext, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons'
import { SettingContext } from '../../store/SettingProvider'
import { DashboardContext as TimeLineDashboard } from '../../store/TimeLineDashboardProvider'
import { AuthContext } from '../../store/AuthProvider'
import useAlertLogList2 from '../../hook/useAlertLogList2'
import logo from '../../images/logo.svg'
import logo_w from '../../images/logo_w.svg'
import logo_icon from '../../images/logo_icon.svg'
import { menu } from '../../functionTool/functionTool'

const { Sider } = Layout

function searchKey(data = [], targetKey) {
    let results = [];
  
    for (let item of data) {
      if (item.key === targetKey) {
        results.push(item);
      }
  
      if (item.children) {
        results = results.concat(searchKey(item.children, targetKey));
      }
    }

    return results;
}

const Sidebar = () => {
    const [collapsed, setCollapsed] = useState(true)
    const { dark } = useContext(SettingContext)
    const { customTimeLineMode } = useContext(TimeLineDashboard)
    const { user } = useContext(AuthContext)
    const { alertLogList2 } = useAlertLogList2({ expired: 'false' })
    const location = useLocation()

    const currentSelectedMenuKey = useMemo(() => {
        if (location.pathname === '/') return '/';
    
        const pathSnippets = location.pathname.split('/').filter((i) => i);
    
        let menuKey = '';
        for (let i = pathSnippets.length; i > 0; i--) {
          const url = `/${pathSnippets.slice(0, i).join('/')}`;
          // const matchMenu = MenuList.find((f) => f.key === url);
          const matchMenu = searchKey(menu(), url);
          if (matchMenu.length) {
            menuKey = matchMenu[0].key;
            break;
          }
        }
    
        return menuKey;
    }, [location, menu]);

    //錯誤訊息數量
    const alerNumber = alertLogList2?.vpp_getAlertLogList2?.filter?.(
        (item) => item.expired !== 'true'
    )?.length

    const isDemo = process.env.REACT_APP_STAGE_NAME !== '正式區' && customTimeLineMode

    const filterMenu = useMemo(() => menu({
        alert: alerNumber,
        customTimeLineMode: isDemo,
        menuList: user?.menuList || [],
    }),[alerNumber, isDemo, user?.menuList])

    return (
        <Sider
            collapsible
            className="app-aside"
            collapsed={collapsed}
            collapsedWidth={60}
            onCollapse={(value) => setCollapsed(value)}
            trigger={
                collapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />
            }
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
            }}
        >
            <div className="app-logo-wrap">
                <div
                    className={`app-logo ${
                        !collapsed ? '' : 'app-logo-collapsed'
                    }`}
                >
                    {!collapsed ? (
                        <Link to="/">
                            <img
                                src={dark ? logo_w : logo}
                                alt="微電能源-能源管理系統"
                            />
                        </Link>
                    ) : (
                        <Link to="/">
                            <img src={logo_icon} alt="微電能源-能源管理系統" />
                        </Link>
                    )}
                </div>
            </div>
            <Menu
                theme={dark ? 'dark' : 'light'}
                mode="inline"
                className="app-aside-menu"
                inlineIndent={16}
                selectedKeys={[currentSelectedMenuKey]}
                items={filterMenu}
            />
        </Sider>
    )
}

export default Sidebar
